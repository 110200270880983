
.media-folder {

  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 4rem;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 5rem;

  .backdrop {
    background: #000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    color: #ffffff;
  }

  .content {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.schedule-preview {
  cursor: pointer;
}

.schedule-field {
  .form-group {
    margin-bottom: 0;
  }
}

.media-thumbnail.lg {
  width: 320px;
  height: 320px;

  img {
    width: initial;
  }
}

.media-thumbnail svg {
  color: #999;
  width: 20px !important;
  height: auto !important;
}


.media-thumbnail {
  div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}


input.duration {
  max-width: 4rem;
}

.status-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.media-upload-queue {
  position: fixed;

  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  bottom: 10px;

  .image-thumbnail {
    max-height: 40px;
  }
  .progress {
    width: 200px;
  }
}

.frameset {
  position: relative;
  width: 100%;
  height: 100%;
  // background: #f3f3f3;
  // background-color: #000000;
  background: #16171e;
  .react-draggable {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #ddd;
    // background: #f0f0f0;
  }
}

.preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.message-preview {
  border: none;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.message-field .message-preview {
  background: #16171e;
}

.login-container {
  // background-image: url("../assets/background.jpeg");
  // background-position: center;
  // background-size: cover;
}

.c-dark-theme {
  color: #e1e1e1;
  // background-color: #0a0b18;
  background-color: #16171f;

  --color: #e1e1e1;

  // --primary: #4638c2;
  --secondary: #4c4f54;
  --success: #45a164;
  --info: #4799eb;
  --warning: #e1a82d;
  --danger: #d16767;
  --light: #20202a;
  --dark: #181924;

  a {
    // color: #7267d3;
  }

  // Core ui

  .c-header {
    background: #2c2c34;
    border-bottom: 0;
  }
  .c-header .c-subheader {
    margin-top: -1px;
    border-top: 1px solid #181924;
  }
  .c-subheader {
    background: #2c2c34;
    border-bottom: 0;
  }

  .c-footer {
    color: #e1e1e1;
    background: #1e1e29;
    border-top: 1px solid #181924;
  }

  .c-sidebar {
    color: #e1e1e1;
    background: #2c2c34;
    border: 0;

    .c-active.c-sidebar-nav-dropdown-toggle,
    .c-sidebar-nav-link.c-active {
      color: #fff;
      background: hsla(0, 0%, 100%, 0.05);
    }
    .c-sidebar-nav-dropdown-toggle,
    .c-sidebar-nav-link {
      color: hsla(0, 0%, 100%, 0.9);
      background: 0 0;
    }

    .c-sidebar-nav-title {
      color: hsla(0, 0%, 100%, 0.6);
    }

    .c-sidebar-nav-title {
      color: hsla(0, 0%, 100%, 0.6);
    }
  }

  // Bootstrap

  .card {
    background-color: #23242d;
    border-color: #181924;
  }

  .card-header {
    background-color: #20202a;
    border-color: #181924;
  }

  .card-footer {
    background-color: #20202a;
    border-color: #181924;
  }

  .breadcrumb {
    background-color: transparent;
    border-color: #1e1e29;
  }

  .table {
    color: #e1e1e1;
    background-color: transparent;
  }

  .table th,
  .table td {
    border-top-color: #181924;
  }

  .table thead th {
    border-bottom-color: #181924;
  }

  .table-primary,
  .table-primary > th,
  .table-primary > td {
    color: #4f5d73;
    background-color: #09407b;
  }

  .modal-content {
    background-color: #2c2c34;
    border-color: #2c2c34;
  }

  .page-item .page-link {
    color: #e1e1e1;
    background-color: hsla(0, 0%, 100%, 0.075);
    border-color: #181924;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }

  .page-item.disabled .page-link {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: hsla(0, 0%, 100%, 0.025);
    border-color: #181924;
  }

  .dropdown-menu {
    color: #e1e1e1;
    background-color: #34343b;
    border-color: #181924;
    .dropdown-item {
      color: #e1e1e1;
      &:hover {
        color: #34343b;
      }
    }
  }

  .form-control {
    color: #e1e1e1;
    background-color: hsla(0, 0%, 100%, 0.1);
    border-color: hsla(0, 0%, 100%, 0.15);
  }

  .custom-select {
    color: #e1e1e1;
    background: hsla(0, 0%, 100%, 0.1);
    border-color: rgba(0, 0, 21, 0.3);
  }

  select.form-control option {
    background-color: #222;
  }

  // Admin kit

  --neutral-0: #2c2c34;

  .drawer-container .drawer-content {
    background-color: #20202a;
    border-color: #181924;
  }

  .inline-items .inline-item {
    background-color: #20202a;
    border-color: #181924;

    &.delete {
      background: #8e3e46;
      border-color: #8e3e46;
    }
  }

  .inline-items .drag-handle {
    background-color: #23242d;
    border-color: #181924;
  }

  .fieldset {
    background-color: #23242d;
    border-color: #181924;
  }
}

.message-field {
  .group-field {
    position: relative;
    > label {
      font-weight: bold;
      position: absolute;
      top: -10px;
      left: 10px;
      padding: 0 5px;
      background: #23242d;
    }
    .group-fields {
      padding-top: 1rem;

      &.layout-vertical {
        flex-direction: column;
      }
    }

    margin-bottom: 2rem;
    margin-top: 2rem;
  }

}



.canvas-presets {
  .preset {
    cursor: pointer;
    i {
      font-size: 5rem;
      margin-bottom: 1rem;
    }
  }
}

.canvas-stage {
  display: flex;


  .stage-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .canvas-sidebar {
    padding: 2rem;
    width: 100%;
    max-width: 30rem;
  }

  .align-btns {
    svg {
      fill: #636f83;
      width: .75rem;
      height: auto;

    }

    .btn:hover {
      svg {
        fill: #FFFFFF;

      }
    }
  }


  .toolbar {
    display: flex;
    margin-bottom: .5rem;
    .toolbar-btn {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      border: solid 1px #666;
      color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: .5rem;
      border-radius: .5rem;

      &:hover {
        background-color: #333;
      }
    }

    .toolbar-divider {
      margin-right: .5rem;
      height: 2rem;
      border-right: solid 1px #666;
    }
  }

}

.c-dark-theme  {

.template-fields {
    .form-control {

      background-color: #ccc;
      color: #222;

  }
}
}


.message-field{
  position: relative;
  .preview-card {
    position: sticky;
    top: 0;
  }
}




.support-article {
  
}
