// Here you can add other styles


.media-thumbnail {
  background-image: url("../assets/no-pattern.png");

  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 30px;

  border-radius: .5rem;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 80px;

  }
}





.variant-item {
  padding: 10px 0;
  border-bottom: solid 1px #ccc;
}

.variant-item label {
  margin-left: 10px;
  margin-right: 10px;
}


.image-thumbnail {
  max-width: 100px;
  max-height: 100px;
}




.support-content {

  h1, h2, h3, h4, h5, h6 {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }


  p {
    display: block;
    // margin-top: 1rem;
    margin-bottom: 1rem;

  }
  p:empty:before {
    content: ' ';
    white-space: pre;
  }

  img {
    max-width: 600px;
    max-height: 300px;
    height:auto;

    margin: 1rem;
  }

}



.variant-row {
  cursor: pointer;
  img {
    width: 30px
  }
}


.fieldset {
  display: flex;
  flex-direction: column
}



button.action-btn:disabled {
  cursor: not-allowed;

}




.bg-primary-light {
  color: #4f5d73;
  background-color: #b8daff;
}



.loading-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
