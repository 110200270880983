

.drawer-container {
  width: 40px;
  // position: relative;

  // transition: width .5s;


  .drawer-content {

    position: fixed;
    top: 0;
    bottom: 0;

    z-index: 1030;

    width: 256px;

    background-color: #ffffff;
    border-left: solid 1px #dddddd;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    overflow: auto;

    transition: transform .5s;

    &:hover {
      transform: translate(-216px, 0);
    }
  }

  &.temp .drawer-content {
    transform: translate(-216px, 0);
  }



  &.pinned {

    width: 256px;
    .drawer-content {
      box-shadow: none;

      transition: none;

      transform: translate(0, 0);
      &:hover {
        transform: translate(0, 0);
      }
    }

  }

  .drawer-list {
    list-style: none;
    padding: 0;

    .item {

    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
